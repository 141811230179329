export const arrMethods = {
    methods: {
        /**
         * Собираем массив со счетом. Если счет пустой, то собираем пустой массив
         * @param num - число, равное кол-ву раундов.
         * @param score - массив со счетами
         * */
        createScoreArr(num, score = [], markers) {
            return score.length ? this.createScoreArrFromScore(num, score, markers) : this.createEmptyScoreArr(num);
        },
        createEmptyScoreArr(num) {
            let arr = [];

            for (let i = 0; i < num; i++) {
                arr.push({
                    value: '',
                    disabled: false,
                    placeholder: '',
                    marker: ''
                });
            }
            return arr;
        },
        createScoreArrFromScore(maxLength, score, markers) {
            let arr = [];

            for (let i = 0; i < maxLength; i++) {
                let scoreObj = {
                    value: null,
                    disabled: true,
                    placeholder: '-',
                    marker: null
                };

                if (score[i] !== undefined) {
                    scoreObj = {
                        value: score[i],
                        disabled: score[i] === null || '',
                        placeholder: '-',
                        marker: markers[i]
                    };
                }

                arr.push(scoreObj);

            }
            return arr;
        },
        changeScorePlaceholder(score, alterable, newItem) {
            /*
              alterable - заменяемое значение
              newItem - чем заменяем
            */
            for (let i = 0; i < score.length; i++) {
                if (score[i].placeholder === alterable) {
                    score[i].placeholder = newItem;
                }
            }
            return score;
        },
    }
};
